@import "../../styles/global/vars.scss";

footer {
  margin-top: auto;
}

.footer {
  background-color: $header_back_ground_color;
  box-shadow: $themeboxfootershadow;
  padding: 1rem;
  text-align: center;
  font-family: $app_font_family;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.linkStyle {
  text-decoration: none;
  color: black;
  :hover {
    cursor: pointer;
  }
}
