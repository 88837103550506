.CardStyle {
  padding: 10px;
  min-width: 100% !important;
}

.imageView {
  width: 100%;
  height: 30vh;
  object-fit: cover;
}
