@import "../../styles/global/vars.scss";

.containerSecu {
  width: 100%;
  padding: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  border-width: 0.2rem;
}

.submitButton {
  background: $phaze_button_color;
  color: $header_back_ground_color;
  border: 1px solid #eee;
  border-radius: 20px;
  box-shadow: 5px 5px 5px #eee;
  text-shadow: none;
  font-size: $theme_name_font_size;
  width: 50%;

  &:hover {
    color: $header_back_ground_color;
  }
}
