@import "../../styles/global/vars.scss";

.HeaderStyle {
  background-color: $header_back_ground_color;
  height: 5rem;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
  box-sizing: border-box;
  z-index: 90;
}

.buttonBold {
  border: none;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  outline: none;
  font-weight: $small_font_weight;
}

.Logo {
  font-weight: $large_font_weight;
  padding: 10px;
  box-sizing: border-box;
  font-size: $detailpage_font_size;
}

.Logo a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: $tablet_size) {
  .Toolbar {
    padding: 0 0.9rem;
  }
}

@media (max-width: $mobile_size) {
  .buttonBold {
    font-size: $homepage_font_size;
    font-weight: $small_font_weight;
  }
  .Toolbar {
    padding: 0 1rem;
  }
  .Logo {
    padding: 0rem;
    font-size: $homepage_font_size;
  }
}
