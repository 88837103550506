@import "~bootstrap/scss/bootstrap";

$light_background : hsl(0, 0%, 98%);
$light_text_element : hsl(0, 0%, 100%);
$light_input : hsl(0, 0%, 52%);
$light_text_color: hsl(200, 15%, 8%);


/*Font styles*/
$homepage_font_size : 14px;
$detailpage_font_size: 16px;
$theme_font_size_mobile: 12px;
$theme_name_font_size: 20px;
$app_font_family: 'Nunito Sans', 'Times New Roman';
$small_font_weight : 300;
$medium_font_weight : 600;
$large_font_weight : 800;

$mobile_size: 375px;
$tablet_size: 950px;
$desktop_size: 1440px;

$header_back_ground_color : rgb(245, 245, 245);
$themeboxfootershadow : 0px 5px 3px 9px rgba(0, 0, 0, 0.15);
$phaze_button_color : rgb(65,102,245);


