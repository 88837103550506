@import "../../styles/global/vars.scss";

/* .GiftContainer{
    display:flex; 
    flex-direction:row;
} */

.GiftRedeemContainer {
  // max-width: fit-content;
  padding: 10px;

  @media screen and (max-width: $tablet_size) {
    margin-bottom: 100px;
  }
}
